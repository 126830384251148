
























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import TagList from '@bertazzoni/back/components/TagList.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import DatePicker from '@bertazzoni/back/components/DatePicker.vue'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import StatsService from '@bertazzoni/common/src/services/StatsService'
import EntitiesService from '@bertazzoni/common/src/services/EntitiesService'
import ModulesService from '@bertazzoni/common/src/services/ModulesService'
import ContentService from '@bertazzoni/common/src/services/ContentService'
import { EntityType } from '@bertazzoni/common/src/models/entity.model'
import { statsFilter } from '@bertazzoni/common/src/models/stats.model'
import { TableInfo } from '@bertazzoni/back/models/table.model'
import { Mod } from '@bertazzoni/common/src/models/module.model'
import { Content } from '@bertazzoni/common/src/models/content.model'
import EventBus from '@bertazzoni/common/src/helpers/eventBus'
import moment from 'moment'

@Component({
  components: { Loader, TagList, Icons, DatePicker, PaginatedTable }
})
export default class StatsContainer extends Vue {
  private pageLoaded = false
  private loading = false
  private keyFilter = 0
  private contentlist: Content[] = []
  private currentModule: Mod = new Mod()
  private dateList: string[] = ['1 day', '1 month', '1 year', 'ever']
  private dateCursor = '1 month'
  private moduleStat = { completed: 0, avgScore: 0, questionAvg: [] }
  private userStat = { total: 0, active: 0, inactive: 0 }
  private tableInfoQuiz: TableInfo = new TableInfo()
  private tableInfoContent: TableInfo = new TableInfo()
  private currentUser = this.$store.getters['User/currentUser']
  private dealerList = []
  private statsFilter: statsFilter = {}

  async created(): Promise<void> {
    try {
      this.dealerList = await EntitiesService.findEntitiesByType(
        EntityType.dealer,
        this.currentUser.linkedEntityId[0]
      )
    } catch {
      this.dealerList = []
    }
    this.currentModule = await ModulesService.findOne(this.$route.params.id)
    //await this.getContentList()
    await this.searchAllStats()
    this.setTablesInfo()
    this.pageLoaded = true
  }
  async getContentList(): Promise<void> {
    this.contentlist = await ContentService.findByIds(this.currentModule.contentIDs)
  }
  setTablesInfo(): void {
    this.tableInfoContent.getCustomTable().isPaginated = false
    this.tableInfoContent.getCustomTable().displayButton = false
    this.tableInfoQuiz.getCustomTable().isPaginated = false
    this.tableInfoQuiz.getCustomTable().displayButton = false
  }
  async searchAllStats(): Promise<void> {
    EventBus.$emit('close', 'all')
    this.changeLoading()
    try {
      const completed = StatsService.moduleCompleted(this.$route.params.id)
      const avgScore = StatsService.moduleAvgScore(this.$route.params.id)
      const questionAvg = await StatsService.moduleQuestionsAvg(this.$route.params.id)
      const data = await Promise.all([completed, avgScore, questionAvg])
      this.moduleStat.completed = data[0]
      this.moduleStat.avgScore = data[1]
      this.moduleStat.questionAvg = data[2]
    } catch {
      EventBus.$emit('close', 0)
    }
    await this.getContentsStats()
    this.changeLoading()
  }
  async getContentsStats(): Promise<void> {
    if (this.currentModule.contentIDs.length > 0) {
      try {
        const promises = []
        this.currentModule.contentIDs.map(async (id: string) => {
          promises.push(StatsService.contentViews(id))
        })
        const data = await Promise.all(promises)
        this.contentlist = data
        // data.map((stat: number, index) => {
        //   this.contentlist[index].views = stat
        // })
      } catch {
        console.log("error")
        this.contentlist = []
        // this.contentlist.map((content: Content) => {
        //   content.views = 0
        // })
        EventBus.$emit('close', 'all')
      }
    }
  }
  async selectDealer(dealer: string): Promise<void> {
    this.statsFilter.filteredEntities
      ? this.statsFilter.filteredEntities.push(dealer)
      : (this.statsFilter.filteredEntities = [dealer])
    this.keyFilter++
    await this.searchAllStats()
  }
  async removeDealer(dealer: string): Promise<void> {
    this.statsFilter.filteredEntities.splice(this.statsFilter.filteredEntities.indexOf(dealer), 1)
    if (this.statsFilter.filteredEntities.length === 0) delete this.statsFilter.filteredEntities
    this.keyFilter++
    await this.searchAllStats()
  }
  async resetAllDealer(): Promise<void> {
    delete this.statsFilter.filteredEntities
    this.keyFilter++
    await this.searchAllStats()
  }
  changeLoading(): void {
    this.loading = !this.loading
  }
  exportStat(): void {
    window.print()
  }
  @Watch('dateCursor')
  async newDate(newValue: string) {
    if (newValue === 'ever') {
      if (this.statsFilter.dateCursor) delete this.statsFilter.dateCursor
    } else {
      switch (newValue) {
        case '1 day':
          this.statsFilter.dateCursor = new Date(
            moment()
              .subtract(1, 'day')
              .format('YYYY,MM-DD HH:mm:ss')
          )
          break
        case '1 month':
          this.statsFilter.dateCursor = new Date(
            moment()
              .subtract(1, 'month')
              .calendar()
          )
          break
        default:
          this.statsFilter.dateCursor = new Date(
            moment()
              .subtract(1, 'years')
              .calendar()
          )
          break
      }
    }
    await this.searchAllStats()
  }
}
